<template>
	<div class="content-wrapper ">
		<h1>Time Line</h1>

		<b-card class="mb-4">
			<b-button @click="$router.push('/axerta/lista-dossier/')" size="sm">
				Vai ai dossier
			</b-button>
		</b-card>

		<b-card class="mb-4">
			<h4>Legenda</h4>
			<b-row v-if="loading">
				Caricamento in corso...
			</b-row>
			<b-row v-else>
				<div
					v-for="manager in calendarOptions.managers"
					:key="manager.id"
					class="col-12 col-md-4 col-lg-2 flex align-items-center"
				>
					<b-icon-circle-fill
						:style="{ color: manager.color }"
					></b-icon-circle-fill>
					<span class="ml-2 font-xs">{{ manager.name }}</span>
				</div>
			</b-row>
		</b-card>

		<b-card v-if="!currentPlace">
			<GmapAutocomplete
				class="w-100"
				@place_changed="setPlace"
				placeholder="Inserisci il luogo di partenza"
			/>
		</b-card>
		<b-card v-else>
			<div class="d-flex justify-content-between align-items-center">
				<div>
					<b-icon icon="geo-alt-fill"></b-icon>
					<span class="ml-2">{{ currentPlace.formatted_address }}</span>
				</div>
				<b-button
					@click="currentPlace = null"
					variant="outline-danger"
					size="sm"
					pill
				>
					<b-icon icon="x"></b-icon>
				</b-button>
			</div>
		</b-card>

		<b-card>
			<div v-if="loadingFullCalendar" class="w-100">
				Caricamento in corso...
			</div>
			<div v-else id="chart">
				<FullCalendar
					ref="fullCalendar"
					class="demo-app-calendar"
					:options="calendarOptions"
				>
					<template v-slot:eventContent="arg">
						<b>{{ arg.timeText }}</b>
						<i>{{ arg.event.title }}</i>
					</template>
				</FullCalendar>
			</div>
		</b-card>

		<b-sidebar
			class="right-sidebar"
			id="sidebar-ordine-di-servizio"
			right
			shadow
			lazy
			:visible="true"
			style="z-index:901 !important"
			v-model="sidebarOrdineVisible"
		>
			<sbOrdineDiServizio
				:idOrdine="tempIdOrdine"
				:dataInizio="tempDataInizio"
				:dataFine="tempDataFine"
				:cieSelezionato="tempIdCieSelezionato"
				:dossierName="tempDossier.text"
				:dossierID="tempDossier.value"
				:currentPlaceCoords="currentPlaceCoords"
				:currentPlace="currentPlace"
				@bv_toggle_collapse="resetData"
			></sbOrdineDiServizio>
		</b-sidebar>

		<b-modal
			id="modal-seleziona-dossier"
			v-model="modalDossierVisible"
			title="Seleziona Dossier"
		>
			Su quale dossier stai lavorando?

			<b-form-select
				v-model="tempDossier"
				@change="handleChangeDossier($event)"
			>
				<option
					v-for="dossier in dossierOptions"
					:key="dossier.value"
					:value="dossier"
					>{{ dossier.text }}</option
				>
			</b-form-select>
			<template #modal-footer>
				<div class="w-100">
					<b-button
						variant="primary"
						class="float-right"
						@click="openSidebarEvent"
					>
						OK
					</b-button>
				</div>
			</template>
		</b-modal>
	</div>
</template>

<script>
	import AxiosService from "../../axiosServices/AxiosService";
	import FullCalendar from "@fullcalendar/vue";
	import dayGridPlugin from "@fullcalendar/daygrid";
	import timeGridPlugin from "@fullcalendar/timegrid";
	import interactionPlugin from "@fullcalendar/interaction";
	import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
	import multiMonthPlugin from "@fullcalendar/multimonth";

	import itLocale from "@fullcalendar/core/locales/it";

	import sbOrdineDiServizio from "@/components/axerta/sbOrdineDiServizio.vue";

	import { iconExists, getIcon, buildIcon } from "@iconify/vue2";

	import { sortBy, tail } from "lodash";
	import { calcDistance } from "../../utils/geometry";

	export default {
		created() {
			this.getDossierManagerOptions();
			this.getCIEOptions();
			this.getDossierOptions();
			this.getData();
			this.getCieSet_Mezzi();
			this.getCieSet_Tools();
		},

		components: {
			FullCalendar,
			sbOrdineDiServizio,
		},
		data() {
			return {
				dossierSelected: {},
				modalDossierVisible: false,
				tempIdCieSelezionato: 0,
				tempIdOrdine: 0,
				tempIdDossier: 0,
				tempDossier: "",
				tempDataInizio: "",
				tempDataFine: "",
				tempEvent: null,
				sidebarOrdineVisible: false,
				calendarOptions: {
					plugins: [
						dayGridPlugin,
						timeGridPlugin,
						resourceTimelinePlugin,
						interactionPlugin,
						multiMonthPlugin,
					],
					locale: itLocale,
					dateClick: this.onDateClick,
					select: this.onSelect,
					unselect: this.onUnselect,
					schedulerLicenseKey: "CC-Attribution-NonCommercial-NoDerivatives",
					headerToolbar: {
						left: "prev,next today",
						center: "title",
						right: "resourceTimelineMonth,multiMonthYear",
					},
					initialView: "resourceTimelineMonth",

					resources: [],

					events: [],
					editable: false,
					selectable: true,
					selectMirror: false,
					dayMaxEvents: true,
					weekends: true,

					eventClick: this.onEventClick,
					eventsSet: this.onEvents,
					resourceLabelDidMount: this.onResourceLabelDidMount,

					managers: [],
				},

				currentEvents: [],

				filter: {
					dossierManager: null,
					CIE: null,
					dossier: null,
					customer: null,
					costoKm: ["", ""],
					mezzi: [],
					tools: [],
					disponibilita: [],
				},
				dossierManagerOptions: [{ value: null, text: "Dossier Manager" }],
				CIEOptions: [{ value: null, text: "CIE" }],
				dossierOptions: [{ value: null, text: "Dossier" }],
				customerOptions: [{ value: null, text: "Customer" }],
				loading: false,
				loadingFullCalendar: false,
				mezziOptions: [],
				toolsOptions: [],
				distanzeFasulle: [
					"5 km",
					"12 km",
					"13 km",
					"21 km",
					"21 km",
					"38 km",
					"59 km",
					"62 km",
					"130 km",
				],
				currentPlace: null,
				currentPlaceCoords: null,
			};
		},

		methods: {
			handleChangeDossier(e) {
				console.log(e);
				this.dossierSelected = e;
			},
			setPlace(place) {
				this.currentPlace = place;
				this.currentPlaceCoords = place.geometry.location.toJSON();

				this.getData();
			},
			calcDistance(mk1, mk2) {
				const R = 6371.071; // Radius of the Earth in kilometers
				const rlat1 = mk1.lat * (Math.PI / 180); // Convert degrees to radians
				const rlat2 = mk2.lat * (Math.PI / 180); // Convert degrees to radians
				const difflat = rlat2 - rlat1; // Radian difference (latitudes)
				const difflon = (mk2.lng - mk1.lng) * (Math.PI / 180); // Radian difference (longitudes)
				const d =
					2 *
					R *
					Math.asin(
						Math.sqrt(
							Math.sin(difflat / 2) * Math.sin(difflat / 2) +
								Math.cos(rlat1) *
									Math.cos(rlat2) *
									Math.sin(difflon / 2) *
									Math.sin(difflon / 2)
						)
					);
				return d;
			},
			onResourceLabelDidMount() {
				let resourcesFromDom = tail(
					document.querySelectorAll("span.fc-datagrid-cell-main")
				);

				let resources = [];

				if (this.calendarOptions.resources[0].distance) {
					resources = sortBy(this.calendarOptions.resources, "distance");
				} else {
					resources = sortBy(this.calendarOptions.resources, "id");
				}

				resourcesFromDom.forEach((resource, index) => {
					resource.innerHTML =
						(resources[index].distance == 0
							? ""
							: resources[index].distance.toFixed(2) + "km - ") +
						resources[index].title +
						"<br>";

					resources[index].skillsRisorsa.mezzi.forEach((mezzo) => {
						if (mezzo.enabled) {
							let svg = document.createElementNS(
								"http://www.w3.org/2000/svg",
								"svg"
							);
							let icon = document.createElementNS(
								"http://www.w3.org/2000/svg",
								"path"
							);
							if (iconExists(mezzo.icon)) {
								icon = getIcon(mezzo.icon);

								let builtIcon = buildIcon(icon, {
									color: "#000000",
									width: "24px",
									height: "24px",
									xmlns: "http://www.w3.org/2000/svg",
									"xmlns:xlink": "http://www.w3.org/1999/xlink",
									"aria-hidden": "true",
									role: "img",
									viewBox: "0 0 24 24",
									class: "iconify iconify--mdi",
								});

								svg.innerHTML = builtIcon.body;
								svg.setAttribute("width", "24px");
								svg.setAttribute("height", "24px");

								resource.appendChild(svg);
							}
						}
					});

					resources[index].skillsRisorsa.tools.forEach((tool) => {
						if (tool.enabled) {
							let svg = document.createElementNS(
								"http://www.w3.org/2000/svg",
								"svg"
							);
							let icon = document.createElementNS(
								"http://www.w3.org/2000/svg",
								"path"
							);
							if (iconExists(tool.icon)) {
								icon = getIcon(tool.icon);

								let builtIcon = buildIcon(icon, {
									color: "#000000",
									width: "1.5em",
									height: "1.5em",
									xmlns: "http://www.w3.org/2000/svg",
									"xmlns:xlink": "http://www.w3.org/1999/xlink",
									"aria-hidden": "true",
									role: "img",
									viewBox: "0 0 24 24",
									class: "iconify iconify--mdi",
								});

								svg.innerHTML = builtIcon.body;
								svg.setAttribute("width", "1.5em");
								svg.setAttribute("height", "1.5em");

								resource.appendChild(svg);
							}
						}
					});
				});
			},

			getCieSet_Mezzi() {
				const service = new AxiosService("Option/CieSet_Mezzi");
				service.read().then((res) => {
					this.mezziOptions = res;
				});
			},
			getCieSet_Tools() {
				const service = new AxiosService("Option/CieSet_Tools");
				service.read().then((res) => {
					this.toolsOptions = res;
				});
			},
			openSidebarEvent() {
				this.modalDossierVisible = false;
				this.sidebarOrdineVisible = true;
				this.tempIdDossier = null;
				this.tempDataInizio = this.tempEvent.startStr
					? this.tempEvent.startStr
					: this.tempEvent.dateStr;
				this.tempDataFine = this.tempEvent.endStr
					? this.tempEvent.endStr
					: this.tempEvent.dateStr;
				this.tempIdCieSelezionato = Number(this.tempEvent.resource.id);
			},
			onDateClick(event) {
				this.modalDossierVisible = true;
				this.tempEvent = event;
			},
			onSelect(event) {
				this.modalDossierVisible = true;
				this.tempEvent = event;
			},
			onUnselect() {
				this.tempIdDossier = null;
				this.tempDataInizio = "";
				this.tempDataFine = "";
			},
			onEventClick(event) {
				console.log(event);
				this.tempIdOrdine = Number(event.event.id);

				if (this.tempIdOrdine) {
					this.sidebarOrdineVisible = true;
				}
			},

			resetData() {
				this.tempIdOrdine = 0;
				this.dossierName = "";
				this.sidebarOrdineVisible = false;
				this.tempDataInizio = "";
				this.tempDataFine = "";
				this.tempIdCieSelezionato = 0;
				this.tempDossier = "";
				this.getData();
			},
			getData() {
				this.loading = true;
				this.loadingFullCalendar = true;
				const service = new AxiosService(
					"DossierManager/GetOrdiniDiServizioCalendar"
				);

				service
					.read()
					.then((res) => {
						this.calendarOptions.resources = res.resources;
						this.calendarOptions.events = res.events;
						this.calendarOptions.managers = res.managers;

						this.calendarOptions.resources.forEach((resource) => {
							this.assignRandomLocation(resource);
							if (this.currentPlace) {
								resource.distance = calcDistance(
									this.currentPlaceCoords,
									resource.location
								);
							} else {
								resource.distance = 0;
							}
						});

						this.calendarOptions.events.forEach((event) => {
							if (event.url == null) {
								delete event.url;
							}
						});
					})
					.finally(() => {
						this.loading = false;
						this.loadingFullCalendar = false;
					});
			},
			assignRandomLocation(resource) {
				const minLat = 35.5; // Minimum latitude value in Italy
				const maxLat = 47.1; // Maximum latitude value in Italy
				const minLng = 6.6; // Minimum longitude value in Italy
				const maxLng = 18.5; // Maximum longitude value in Italy

				const lat = Math.random() * (maxLat - minLat) + minLat;
				const lng = Math.random() * (maxLng - minLng) + minLng;

				resource.location = { lat, lng };
			},
			getDossierManagerOptions() {
				const service = new AxiosService("UserBo");
				service.read().then((res) => {
					res.forEach((element) => {
						this.dossierManagerOptions.push({
							value: element.id,
							text: element.nome + " " + element.cognome,
						});
					});
				});
			},
			getCIEOptions() {
				const service = new AxiosService("User");
				service.read().then((res) => {
					res.forEach((element) => {
						this.CIEOptions.push({
							value: element.id,
							text: element.nome + " " + element.cognome,
						});
					});
				});
			},
			getDossierOptions() {
				const service = new AxiosService("DossierManager/GetDossiers");
				service.read().then((res) => {
					res.forEach((element) => {
						this.dossierOptions.push({
							value: element.id,
							text: element.codice + " - " + element.titolo,
						});
					});
				});
			},
		},
	};
</script>

<style scoped lang="scss">
	.dropdown-menu {
		z-index: 999;
	}
</style>
