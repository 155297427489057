<template>
	<div class="px-4  py-5">
		<div
			v-if="tmpItem && tmpItem.id"
			class="d-flex justify-content-between pt-5"
		>
			<h4>
				{{ tmpItem.titolo || tmpItem.codice }}
			</h4>
		</div>
		<h4 v-else>Nuovo ordine di servizio</h4>

		<b-row v-if="dossierName" class="mb-4">
			<b-col>
				<span
					>Dossier:
					<span style="font-weight: bold;">{{ dossierName }}</span></span
				>
			</b-col>
		</b-row>

		<b-row class="mb-4">
			<b-col>
				<b-form-checkbox v-model="tmpItem.confermato">
					Confermato
				</b-form-checkbox>
			</b-col>
		</b-row>

		<!-- <b-row>
          <b-col>
            <b-form-group label="Codice">
              <b-form-input v-model="tmpItem.codice" />
            </b-form-group>
          </b-col>
        </b-row> -->

		<b-row class="mb-2">
			<b-col>
				<b-form-group label="Titolo">
					<b-form-input v-model="tmpItem.titolo" />
				</b-form-group>
			</b-col>
		</b-row>

		<b-row>
			<b-col>
				<b-form-group label="Luogo inizio Servizio">
					<div
						v-if="tempPlace || tmpItem.luogo"
						class="d-flex justify-content-between align-items-center mb-2"
					>
						<div>
							<b-icon icon="geo-alt-fill"></b-icon>
							<span v-if="tempPlace" class="ml-2">{{
								tempPlace.formatted_address
							}}</span>
							<span v-else-if="tmpItem" class="ml-2">{{ tmpItem.luogo }}</span>
						</div>
						<b-button
							@click="tempPlace = null"
							variant="outline-danger"
							size="sm"
							pill
						>
							<b-icon icon="x"></b-icon>
						</b-button>
					</div>

					<CustomGmapAutocomplete
						:placeholder="tempPlace || tmpItem.luogo"
						@gmap-autocomplete::place-changed="handlePlaceChanged"
					></CustomGmapAutocomplete>

					<GmapMap
						ref="gmapMap"
						:center="markers[0]"
						:zoom="14"
						map-type-id="roadmap"
						style="width: 100%; height: 400px"
					>
						<gmap-custom-marker
							:key="index"
							v-for="(marker, index) in markers"
							:marker="marker"
							alignment="top"
						>
							<img
								src="@/assets/images/gmapMarker_Axerta.svg"
								style="width:30px"
							/>
						</gmap-custom-marker>
					</GmapMap>
				</b-form-group>
			</b-col>
		</b-row>

		<b-row class="mb-4">
			<b-col>
				<label class="mr-2">CIE Assegnato</label>
				<b-form-select v-model="tmpItem.idCie" :options="CieOptions">
				</b-form-select>
			</b-col>
		</b-row>

		<b-row class="mb-2">
			<b-col>
				<b-form-group label="Indagato">
					<b-form-input v-model="tmpItem.Indagato" />
				</b-form-group>
			</b-col>
		</b-row>

		<b-row v-if="dossier && tmpItem.elementi">
			<b-col>
				<b-form-group label="Elementi Dossier a supporto">
					<TagsInput
						v-model="elementiSelezionati"
						:tagsList="dossier.elementi"
					/>
				</b-form-group>
			</b-col>
		</b-row>

		<b-row v-if="dossier">
			<b-col>
				<b-form-group label="Data ora suggerita">
					<b-row>
						<b-col>
							<b-form-datepicker
								v-model="customDataOra_inizio"
								:min="new Date(dossier.dataInizio)"
								:max="new Date(dossier.dataFine)"
								locale="it"
							></b-form-datepicker>
						</b-col>
						<b-col>
							<vue-timepicker
								class="custom-timepicker"
								advanced-keyboard
								manual-input
								v-model="tmpItem.ora_suggerita"
							></vue-timepicker>
						</b-col>
					</b-row>
				</b-form-group>
			</b-col>
		</b-row>

		<b-row>
			<b-col>
				<b-form-group
					:label="`Durata Stimata (massimo ${durataMassima} giorni) `"
				>
					<b-form-input
						type="number"
						v-model="tmpItem.durataStimata"
						@change="handleChangeDurataStimata"
						:max="durataMassima"
						:min="0"
					/>
				</b-form-group>
			</b-col>
		</b-row>

		<b-row>
			<b-col>
				<b-form-group label="Descrizione Ordine di Servizio">
					<b-form-textarea
						v-model="tmpItem.annotazioni"
						rows="3"
						max-rows="6"
					></b-form-textarea>
				</b-form-group>
			</b-col>
		</b-row>

		<b-row>
			<b-col>
				<b-form-group label="Numero CIE assegnati">
					<b-form-input type="number" v-model="tmpItem.numeroCie" />
				</b-form-group>
			</b-col>
		</b-row>

		<div class="mt-5 mb-5">
			<b-button
				squared
				:disabled="loading"
				variant="primary"
				size="lg"
				class="big-button"
				@click="saveItem"
			>
				{{ !loading ? "Salva" : "Loading..." }}</b-button
			>
		</div>
	</div>
</template>

<script>
	import AxiosService from "../../axiosServices/AxiosService";
	import GmapCustomMarker from "vue2-gmap-custom-marker";
	import CustomGmapAutocomplete from "@/components/CustomGmapAutocomplete.vue";
	import { adjustDateInInterval } from "@/utils/DatetimeUtils.js";
	// import VueGoogleAutocomplete from "vue-google-autocomplete"
	export default {
		props: {
			idOrdine: Number,
			dataInizio: String,
			dataFine: String,
			cieSelezionato: Number,
			dossierName: String,
			dossierID: Number,
			currentPlaceCoords: Object,
			currentPlace: Object,
		},
		components: {
			"gmap-custom-marker": GmapCustomMarker,
			CustomGmapAutocomplete,
			// "vue-google-autocomplete": VueGoogleAutocomplete,
		},
		created() {
			if (this.tmpItem.titolo.length == 0 || !this.tmpItem.titolo) {
				this.tmpItem.titolo =
					"ODI del " +
					new Date().toLocaleDateString("it", {
						day: "numeric",
						month: "numeric",
						year: "numeric",
					});
			}

			if (this.currentPlaceCoords) {
				this.markers.splice(0);
				this.markers.push(this.currentPlaceCoords);
			}
			if (this.tmpItem.geo) {
				this.markers.splice(0);
				this.markers.push(this.tmpItem.geo);
			}
			if (this.currentPlace) {
				this.tempPlace = { ...this.currentPlace };
			}
			if (this.idOrdine) {
				this.getOrdine();
			} else {
				this.getDossier(this.dossierID);
				this.tmpItem = {
					id: 0,
					idDossier: this.idDossier,
					Dossier: "",
					idDossierManager: this.$store.state.user.user.id,
					DossierManager:
						this.$store.state.user.user.cognome +
						" " +
						this.$store.state.user.user.nome,
					DossierManagerImage: this.$store.state.user.user.avatar_url,
					descrizioneDossier: "",
					Cliente: "",
					idCie: 0,
					Cie: "",
					codice: "",
					titolo: "",
					luogo: "",
					linkMaps: "",
					dataOra_inizio: this.dataInizio ? this.dataInizio : "",
					elementi: [],
					annotazioni: "",
					ora_suggerita: {
						HH: "08",
						mm: "00",
					},
					numeroCie: 1,
					durataStimata: 0,
					confermato: false,
					rilevazioni: 0,
				};
			}

			if (this.dataInizio && this.dataFine) {
				this.dateDiffInDays(this.dataInizio, this.dataFine);
			}

			if (this.cieSelezionato) {
				this.tmpItem.idCie = this.cieSelezionato;
			}

			this.getCIEOptions();
		},

		data() {
			return {
				dossier: null,
				markers: [
					{
						lat: 45.40334398498736,
						lng: 10.848341660757919,
					},
				],
				CieOptions: [{ value: null, text: "CIE" }],
				loading: false,
				tmpItem: {
					id: 0,
					idDossier: 0,
					Dossier: "",
					idDossierManager: 0,
					DossierManager: "",
					DossierManagerImage: null,
					descrizioneDossier: "",
					Cliente: "",
					idCie: 0,
					Cie: "",
					codice: "",
					titolo: "",
					luogo: "",
					linkMaps: "",
					dataOra_inizio: "",
					ora_suggerita: "",
					elementi: [],
					annotazioni: "",
					Indagato: "",
					numeroCie: 1,
					durataStimata: 0,
					confermato: false,
					rilevazioni: 0,
				},
				address: "",
				elementiSelezionati: [],
				tempPlace: null,
				customDataOra_inizio: "",
			};
		},

		methods: {
			getTimeFromString(timeString) {
				const date = new Date(timeString);
				const hours = String(date.getHours()).padStart(2, "0");
				const minutes = String(date.getMinutes()).padStart(2, "0");

				return {
					HH: hours,
					mm: minutes,
				};
			},
			handleChangeDurataStimata() {
				if (this.tmpItem.durataStimata > this.durataMassima) {
					this.tmpItem.durataStimata = this.durataMassima;
				}
			},
			setDataOraInizio() {
				this.tmpItem.dataOra_inizio = adjustDateInInterval(
					this.tmpItem.dataOra_inizio,
					[this.dossier.dataInizio, this.dossier.dataFine]
				);
			},
			getDossier(idDossier) {
				this.loading = true;
				if (!idDossier) return;
				const service = new AxiosService(
					`DossierManager/GetDossier/${idDossier}`
				);
				service
					.read()
					.then((res) => {
						res.elementi.forEach((elemento) => {
							elemento.text = elemento.tag;
							elemento.value = elemento.id;
						});
						this.dossier = res;
					})
					.finally(() => {
						this.setDataOraInizio();
						this.loading = false;
					});
			},
			handlePlaceChanged(place) {
				this.markers.splice(0);
				this.tempPlace = place;
				this.tmpItem.luogo = place.formatted_address;
				this.tmpItem.geo = place.geometry.location.toJSON();
				this.markers.push(place.geometry.location.toJSON());
			},

			addMarker(event) {
				this.markers.splice(0);
				this.markers.push({
					lat: event.latLng.lat(),
					lng: event.latLng.lng(),
				});
			},
			dateDiffInDays(date1, date2) {
				const oneDay = 24 * 60 * 60 * 1000; // One day in milliseconds
				const firstDate = new Date(date1);
				const secondDate = new Date(date2);
				const diffInMs = Math.abs(firstDate - secondDate); // Difference in milliseconds
				this.tmpItem.durataStimata = Math.round(diffInMs / oneDay);
			},
			getOrdine() {
				const service = new AxiosService(
					"DossierManager/GetOrdineDiServizio/" + this.idOrdine
				);

				service
					.read()
					.then((res) => {
						this.tmpItem = { ...res };
						this.tmpItem.dataOra_inizio = res.dataOra_inizio;

						this.tmpItem.dataOra_inizio = res.dataOra_inizio;
						this.customDataOra_inizio = res.dataOra_inizio;

						this.tmpItem.ora_suggerita = this.getTimeFromString(
							this.customDataOra_inizio
						);

						this.tmpItem.elementi.forEach((element) => {
							this.elementiSelezionati.push(element.id);
						});
					})
					.finally(() => {
						if (!this.dossierID) {
							this.getDossier(this.tmpItem.idDossier);
						}
					});
			},
			saveItem() {
				this.loading = true;

				let nuoviSelezionati = [];
				this.elementiSelezionati.forEach((selezionato) => {
					this.dossier.elementi.forEach((elemento) => {
						if (elemento.id == selezionato) {
							nuoviSelezionati.push({
								id: elemento.id,
								tag: elemento.tag,
								descrizione: elemento.descrizione,
								allegati: elemento.allegati,
								delete: false,
							});
						}
					});
					this.tmpItem.elementi = nuoviSelezionati;
				});

				this.tmpItem.dataOra_inizio = this.customDataOra_inizio;

				let dateAndTime = this.customDataOra_inizio.split("T");
				let newTime = `${this.tmpItem.ora_suggerita.HH}:${this.tmpItem.ora_suggerita.mm}:00`;

				if (dateAndTime.length > 1) {
					this.tmpItem.dataOra_inizio = dateAndTime[0] + "T" + newTime;
				} else {
					this.tmpItem.dataOra_inizio =
						this.customDataOra_inizio + "T" + newTime;
				}

				if (this.tmpItem.id) {
					const service = new AxiosService(
						"DossierManager/EditOrdineDiServizio"
					);

					this.tmpItem.elementi;
					service
						.update(this.tmpItem)
						.then((res) => console.log(res))
						.finally(() => {
							this.sidebarOrdineVisible = false;
							this.loading = false;
							this.$emit("bv_toggle_collapse", this.tmpItem);
						});
				} else {
					const service = new AxiosService(
						"DossierManager/AddOrdineDiServizio/" + this.dossierID
					);
					service
						.create(this.tmpItem)
						.then((res) => console.log(res))
						.finally(() => {
							this.sidebarOrdineVisible = false;
							this.loading = false;
							this.$emit("bv_toggle_collapse", this.tmpItem);
						});
				}
			},
			getCIEOptions() {
				const service = new AxiosService("User");
				service.read().then((res) => {
					res.forEach((element) => {
						this.CieOptions.push({
							value: element.id,
							text: element.nome + " " + element.cognome,
						});
					});
				});
			},
		},
		computed: {
			durataMassima() {
				if (!this.dossier || !this.tmpItem.dataOra_inizio) return 0;

				let fine = new Date(this.dossier.dataFine).getTime();
				let inizio = new Date(this.tmpItem.dataOra_inizio).getTime();

				let differenza = Math.abs(fine - inizio);

				return Math.round(differenza / (1000 * 60 * 60 * 24));
			},
		},
	};
</script>
